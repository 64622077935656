import React from 'react'

export default function Faq() {
  return (
    <div className="grid grid-cols-2 gap-4">
        
        <div className="text-center col-span-full font-bold">Wheelbuilding FAQ</div>
        
        <div className="text-sm pl-2">In-Depth Guide to Wheelbuilding</div>
        <div className="text-sm underline flex justify-center items-center text-center">
          <a href="https://www.slowspinsociety.com/home/custom-wheel-building-part2" target="_blank" rel="noreferrer noopener">
            SlowSpinSociety Wheelbuilding Guide
          </a>
        </div>
    </div>
  )
}
