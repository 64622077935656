import React from 'react'
import Hubs from './Hubs'
import CalcAndDisplay from './CalcAndDisplay'
import Rims from './Rims'
import SpokesAndCrosses from './SpokesAndCrosses'
//import Crosses from './Crosses'
import PaypalCalc from './PaypalCalc'
import Navbar from './Navbar'
import { BrowserRouter as Router, Switch, Route,} from "react-router-dom";
import {useState} from 'react';
import HubsAdded from './HubsAdded'
import RimsAdded from './RimsAdded'
import SpokesAndCrossesAdded from './SpokesAndCrossesAdded'
import CalcAndDisplayAdded from './CalcAndDisplayAdded'
import Footer from './Footer'
import LandingBar from './LandingBar'
import Faq from './Faq'
import Contact from './Contact'
import ILTBTracklocross from './ILTBTracklocross'



//IDeas and todos
// Weight addition of all parts
//Actual number + correct rounding
//Print Wheel Data
//Wheel Summary
//Monkeytupe design
//make input open numpad 
//About Section
//Wheelbuilding help section
//Visualization of crosses

//Add link to Pedalroom

//firebase stuff
//npm run build
//firebse init
//firebase deploy

function App() {
  const [isShown, setIsShown] = useState(false);

  const addWheel = event => {
    // toggle shown state
    //setIsShown(current => !current);

    //can also be set to true
    setIsShown(true);
  };

  const removeWheel = event => {
    setIsShown(false);
  }

  

  
  return (
  
    <>
       
    
    <Router>  

    <div className="bg-monkeybgblue font-mono text-monkeytext">
     <Navbar/>
      
     
      <div>
        <Switch>
          <Route path="/" exact component={<App/>}>
          <div>

          <LandingBar />
          
            <form>

              <Rims />

              <Hubs />

              <SpokesAndCrosses/>

            

            </form>
          </div>
          <div>
            <CalcAndDisplay />
          </div>


          <div>

            <div className="grid grid-cols-2 pb-4">
              
              <span className="text-center">
                <button onClick={addWheel} className="border-2 border-monkeylightblue px-6 py-1">Add a Wheel</button>
              </span>
              
              <span className="text-center">
                <button onClick={removeWheel} className="border-2 border-monkeylightblue px-6 py-1">Remove Wheel</button>
              </span>
            </div>

            {/*shows elements on click*/}
            {isShown && (
              <div>

               
                <div>
                  <form>
                    <RimsAdded />

                    <HubsAdded />

                    <SpokesAndCrossesAdded />

                  </form>

                </div>
                <div>
                  <CalcAndDisplayAdded />
                </div>
              </div>
            )}
          </div>



          </Route>

          <Route exact path="/PayPalCalc" component={<PaypalCalc/>}>

            <PaypalCalc/>

          </Route>

          <Route exact path="/FAQ" component={<Faq/>}>
            <Faq />
          </Route>

          <Route exact path="/Contact" component={<Contact/>}>
              <Contact/>
          </Route>

          <Route exact path="/ILTBTracklocross" compnent={<ILTBTracklocross/>}>
            <ILTBTracklocross/>
          </Route>
          

          

        </Switch>
      </div>

      <Footer />

      </div>
    </Router>
    
    </>
  ) 
}
export default App;


