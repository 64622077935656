import React from 'react'


export default function Hubs() {
  return (

    <>
    <div>

      <h1 className="text-3xl text-center text-monkeypink">Hub</h1>

      <div className="grid grid-cols-4 gap-3">
        <div className="col-span-4 text-center font-semibold">
          Flange Diameters
        </div>
        <div className="text-monkeylightblue pl-2">
          Left Flange ⌀
        </div>
        <div className="">
          <input type="number" id="leftflangediameter" className="text-center rounded-lg w-3/4 h-full bg-monkeydarkblue text-monkeytext "></input>
        </div>
        <div className="text-monkeylightblue pl-2">
          Right Flange ⌀
        </div>
        <div className="">
          <input type="number" id="rightflangediameter" className="text-center rounded-lg w-3/4 h-full bg-monkeydarkblue"></input>
        </div>

        <div className="col-span-4 text-center font-semibold">
          Center to Flanges
        </div>
        <div className="text-monkeylightblue pl-2">
          Center to Left
        </div>
        <div className="">
          <input type="number" id="centertoleftflange" className="text-center w-3/4 h-full bg-monkeydarkblue rounded-lg"></input>
        </div>
        <div className="text-monkeylightblue pl-2">
          Center to Right
        </div>
        <div className="">
          <input type="number" id="centertorightflange" className="text-center w-3/4 h-full bg-monkeydarkblue rounded-lg"></input>
        </div>
        
        

      </div>

      <div className="grid grid-cols-5 gap-3">
        <div className="col-span-5 text-center font-semibold pt-3">
          <span className="">Spoke Hole Diameter</span>
        </div>

        <div className=""></div>
        <div className=""></div>
        <div className="">
          <input type="number" id="spokeholediameter" className="pb-2 pt-2 text-center w-full h-full bg-monkeydarkblue rounded-lg"></input>
        </div>
        <div className=""></div>
        <div className=""></div>

      </div>
        
    </div>
    
    
    </>
   
  )
}
