import React from 'react'
import { FaInstagram } from 'react-icons/fa'
import { FaDiscord } from 'react-icons/fa'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer>
      <div class="relative flex items-center">
        <div class="flex-grow border-t border-monkeylightblue"></div>
        <span class="flex-shrink mx-4 text-monkeypink">-</span>
        <div class="flex-grow border-t border-monkeylightblue"></div>
    </div>

       <div className="grid grid-cols-5">
        
        <div className="cols-span-2 font-semibold pl-7 pb-2">
            ILikeTrackBikes
        </div>
        <div className=""></div>
        <div className=""></div>
        <div className="text-xl">
          <a href="https://instagram.com/iliketrackbikes" target="_blank" rel="noreferrer noopener">
            <FaInstagram />
          </a>
        </div>
        <div className="text-xl">

          <Link to='/Contact'><FaDiscord /></Link>

          
          
        </div>
       </div>

      

    </footer>
  )
}
