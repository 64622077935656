import React from 'react'
import { Link } from 'react-router-dom';

export default function LandingBar() {
  return (
    <>
    <div className="bg-monkeydarkblue">
        <div className="grid grid-cols-1 gap-4">
            <div className="font-semibold text-xl text-center">I Like Track Bikes</div>
            <div className="text-center text-lg">Welcome to my Spoke Calculator</div>
            <div className="text-center text-xs">Feel free to contact me incase of questions or inquiries for wheelbuilds </div>
            <div className=""></div>
        </div>

        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-2 text-center text-sm ">
            <Link to="/FAQ" className="border-2 border-monkeylightblue pl-2 pr-2">Wheelbuilding FAQ</Link>
          </div>
          <div className="col-span-2 text-center text-sm">
            <Link to="/Contact" className="border-2 border-monkeylightblue px-12">Contact</Link>
          </div>
          <div className=""></div>
        </div>
    </div>
    
    </>
  )
}
