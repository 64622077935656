import React from 'react'

export default function PointStandings() {
  return (

    <>
    
    <div class="grid grid-cols-6 gap-3 text-center">

    
      
        <div className="font-semibold">Rank</div>
        <div className="text-center font-semibold"> Name </div>
        <div className="text-center font-semibold"> Number </div>
        <div className="text-center font-semibold"> Points </div>
        <div className="text-center col-span-2 font-semibold">Team</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">1</div>
        <div className="">Pepe</div>
        <div className="">12</div>
        <div className="">34</div>
        <div className="col-span-2 text-center">ILTB</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">2</div>
        <div className="">Peter - Brakeless</div>
        <div className="">44</div>
        <div className="">20</div>
        <div className="col-span-2 text-center">fxd.bln</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">3</div>
        <div className="">Pascal</div>
        <div className="">71</div>
        <div className="">16</div>
        <div className="col-span-2 text-center">Happycrew.cc</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">4</div>
        <div className="">Marius - Brakeless</div>
        <div className="">94</div>
        <div className="">11</div>
        <div className="col-span-2 text-center">fxd.bln</div>
        
        <div className="bg-monkeydarkblue text-monkeylightblue">5</div>
        <div className="">Nate - Brakeless</div>
        <div className="">44.2</div>
        <div className="">11</div>
        <div className="col-span-2 text-center"></div>

        <div className="bg-monkeydarkblue text-monkeylightblue">6</div>
        <div className="">Florian - Brakeless</div>
        <div className="">44.1</div>
        <div className="">10</div>
        <div className="col-span-2 text-center">Aldi Nord</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">7</div>
        <div className="">Jojo - Brakeless</div>
        <div className="">87</div>
        <div className="">10</div>
        <div className="col-span-2 text-center">ILTB</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">8</div>
        <div className="">Victor</div>
        <div className="">42(1)</div>
        <div className="">8</div>
        <div className="col-span-2 text-center">fxd.bln</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">9</div>
        <div className="">Xania</div>
        <div className="">666</div>
        <div className="">7</div>
        <div className="col-span-2 text-center">ILTB</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">10</div>
        <div className="">Raja - Brakeless</div>
        <div className=""></div>
        <div className="">7</div>
        <div className="col-span-2 text-center">Aldi Nord</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">11</div>
        <div className="">Patrick - Brakeless</div>
        <div className="">95</div>
        <div className="">6</div>
        <div className="col-span-2 text-center"></div>

        

        <div className="bg-monkeydarkblue text-monkeylightblue">12</div>
        <div className="">Leo - Brakeless</div>
        <div className="">69</div>
        <div className="">4</div>
        <div className="col-span-2 text-center">Ritzelninjas</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">13</div>
        <div className="">Ben</div>
        <div className="">85</div>
        <div className="">4</div>
        <div className="col-span-2 text-center">Only Bens</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">14</div>
        <div className="">Johanna</div>
        <div className="">31</div>
        <div className="">3</div>
        <div className="col-span-2 text-center">BikeBash</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">15</div>
        <div className="">Tim</div>
        <div className=""></div>
        <div className="">3</div>
        <div className="col-span-2 text-center"></div>

        



    </div>

    </>

  )
}
