import React from 'react'

export default function S2NAMECritResults() {
  return (
    <>
    <div className="grid grid-cols-5 gap-2">
        <div className="text-center font-semibold">Rank</div>
        <div className="text-center font-semibold">Name</div>
        <div className="text-center font-semibold">Number</div>
        <div className="text-center font-semibold col-span-2">Team</div>

        <div className="text-center text-monkeylightblue">1</div>
        <div className="text-center">Paul
</div>
        <div className="text-center">9
</div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">2</div>
        <div className="text-center">Moritz
</div>
        <div className="text-center">16
</div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">3</div>
        <div className="text-center">Micha
</div>
        <div className="text-center">17</div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">4</div>
        <div className="text-center">Pascal - fixed brakeless
</div>
        <div className="text-center">69(14)
</div>
        <div className="text-center col-span-2">Happycrew.cc</div>

        <div className="text-center text-monkeylightblue">5</div>
        <div className="text-center">Benny
</div>
        <div className="text-center">3</div>
        <div className="text-center col-span-2">BikeBash</div>

        <div className="text-center text-monkeylightblue">5</div>
        <div className="text-center">Felix
</div>
        <div className="text-center">314</div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">7</div>
        <div className="text-center">Carl
</div>
        <div className="text-center">6</div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">8</div>
        <div className="text-center">Pepe - fixed</div>
        <div className="text-center">12</div>
        <div className="text-center col-span-2">ILTB</div>

        <div className="text-center text-monkeylightblue">9</div>
        <div className="text-center">Tandem
</div>
        <div className="text-center">7</div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">10</div>
        <div className="text-center">Nate - fixed brakeless
</div>
        <div className="text-center">21</div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">11</div>
        <div className="text-center">Florian - fixed brakeless
</div>
        <div className="text-center">19</div>
        <div className="text-center col-span-2">Aldi Nord</div>

        <div className="text-center text-monkeylightblue">12</div>
        <div className="text-center">Raja - fixed brakeless
</div>
        <div className="text-center">99(13)
</div>
        <div className="text-center col-span-2">Aldi Nord</div>


        <div className="text-center text-monkeylightblue">13</div>
        <div className="text-center">Yannik
</div>
        <div className="text-center">15(2)
</div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">14</div>
        <div className="text-center">Niklas
</div>
        <div className="text-center">42(15)</div>
        <div className="text-center col-span-2">Aldi Nord</div>

        <div className="text-center text-monkeylightblue">15</div>
        <div className="text-center">Tim - fixed
</div>
        <div className="text-center">8</div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">16</div>
        <div className="text-center">Andre
</div>
        <div className="text-center">23</div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">17</div>
        <div className="text-center">Marek
</div>
        <div className="text-center">20</div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">18</div>
        <div className="text-center">Ben
</div>
        <div className="text-center">85(11)</div>
        <div className="text-center col-span-2">Only Bens</div>

        <div className="text-center text-monkeylightblue">19</div>
        <div className="text-center">Xania - fixed
</div>
        <div className="text-center">666</div>
        <div className="text-center col-span-2">ILTB</div>

        <div className="text-center text-monkeylightblue">20</div>
        <div className="text-center">Crankboi
</div>
        <div className="text-center">90(4)
</div>
        <div className="text-center col-span-2">
        BikeBash
        </div>

        <div className="text-center text-monkeylightblue">21</div>
        <div className="text-center">Max - fixed
</div>
        <div className="text-center"></div>
        <div className="text-center col-span-2"></div>

        <div className="text-center text-monkeylightblue">DNF</div>
        <div className="text-center">Tomas</div>
        <div className="text-center"></div>
        <div className="text-center col-span-2"></div>

        

        


  </div>


    </>
  )
}
