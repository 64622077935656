import React from 'react'

export default function PointStandings() {
  return (

    <>

    <div className="text-center font-bold">To be Updated </div>
    {/*
    <div class="grid grid-cols-7 gap-3 text-center">

    
      
        <div className="font-semibold">Rank</div>
        <div className="text-center font-semibold col-span-2"> Team </div>
        <div className="text-center font-semibold">Total Points</div>
        <div className="text-center font-semibold"> From Overall </div>
        <div className="text-center font-semibold"> From Fixed </div>
        <div className="text-center font-semibold">From Women</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">1</div>
        <div className="col-span-2">ILTB</div>
        <div className="">52</div>
        <div className="">4</div>
        <div className="">30</div>
        <div className="">18</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">2</div>
        <div className="col-span-2">fxd.bln</div>
        <div className="">37</div>
        <div className="">8</div>
        <div className="">29</div>
        <div className="">0</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">3</div>
        <div className="col-span-2">LKK Gravelmania</div>
        <div className="">22</div>
        <div className="">22</div>
        <div className="">0</div>
        <div className="">0</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">4</div>
        <div className="col-span-2">BikeBash</div>
        <div className="">21</div>
        <div className="">6</div>
        <div className="">3</div>
        <div className="">12</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">5</div>
        <div className="col-span-2">Aldi Nord</div>
        <div className="">17</div>
        <div className="">2</div>
        <div className="">5</div>
        <div className="">10</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">6</div>
        <div className="col-span-2">Ritzelninjas</div>
        <div className="">9</div>
        <div className="">0</div>
        <div className="">4</div>
        <div className="">5</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">7</div>
        <div className="col-span-2">Steppenwolf</div>
        <div className="">7</div>
        <div className="">7</div>
        <div className="">0</div>
        <div className="">0</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">8</div>
        <div className="col-span-2">C-tech-Röntgenteam</div>
        <div className="">6</div>
        <div className="">6</div>
        <div className="">0</div>
        <div className="">0</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">9</div>
        <div className="col-span-2">Potsdam Cycling Crew</div>
        <div className="">5</div>
        <div className="">5</div>
        <div className="">0</div>
        <div className="">0</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">10</div>
        <div className="col-span-2">Basic</div>
        <div className="">4</div>
        <div className="">4</div>
        <div className="">0</div>
        <div className="">0</div>

        </div>

  */}

        

        



    

    </>

  )
}
