import React from 'react'
import { FaInstagram } from 'react-icons/fa'
import { FaDiscord } from 'react-icons/fa'

export default function Contact() {
  return (
    <>
    <div className="grid grid-cols-1 gap-2">
      <div className="text-3xl font-extrabold text-center">
        Contact Me
      </div>
      <div className="text-sm text-center">
       
      </div>
    </div>

  <div className="bg-monkeydarkblue">
  <div className="grid grid-cols-2 gap-4">
      <div className="font-semibold text-xl text-center col-span-full pt-2">Socials</div>
      <div className="text-center text-lg">Instagram - ILikeTrackBikes</div>
      <div className="text-center text-lg">Discord - GiftedOtter#7086 </div>

      <div className="flex justify-center items-center text-4xl">
        <a href="https://instagram.com/iliketrackbikes" target="_blank" rel="noreferrer noopener">
            <FaInstagram />
        </a>
      </div>
      <div className="flex justify-center items-center text-4xl">
        <a href="https://discordapp.com/users/GiftedOtter#7086" target="_blank" rel="noreferrer noopener">
            <FaDiscord />
        </a>
      </div>

      <div className="font-semibold text-xl text-center col-span-full">E-Mail</div>
      <div className="col-span-full text-center">
        iliketrackbikes@gmail.com
      </div>
      <div className=""></div>
  </div>

  <div className="grid grid-cols-4 gap-4">
  
    <div className=""></div>
  </div>
  </div>
  </>
  )
}
