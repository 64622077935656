import React from 'react'

export default function Rims() {
  return (
    <>
    <div class="relative flex items-center">
        <div class="flex-grow border-t border-monkeylightblue"></div>
        <span class="flex-shrink mx-4 text-monkeypink">+</span>
        <div class="flex-grow border-t border-monkeylightblue"></div>
        </div>
    <h1 className="text-center text-3xl text-monkeypink">Rim</h1>
    <div className="grid grid-cols-5">
    
      <div className=""></div>
      <div className="pt-2 pb-2 text-xl text-center content-center text-monkeylightblue">
        ERD
      </div>
      <div className=""></div>
      <div className="col-span-1">
        <input type="number" className="pt-2 pb-2 text-center w-full h-full bg-monkeydarkblue rounded-lg" id="ERD"/>
      </div>
      <div className=""></div>
    </div>
    </>
  )
}
