import React from 'react'

import PointStandings from './PointStandings'
import FixedPointStandings from './FixedPointStandings'
import WomenPointStandings from './WomenPointStandings'
import TeamPointStandings from './TeamPointStandings'
import PastRaces from './PastRaces';
import S2NAMECritResults from './S2NAMECritResults'
//import NeonNightsResults from './NeonNightsResults';

//import ConqueringCobblesResults from './ConqueringCobblesResults';
//import S2MuddyMayheemresults from './S2MuddyMayhemresults';

export default function ILTBTracklocross() {
  return (
    <>
    <div className="grid grid-cols-2 gap-4">
        
        <div className="text-center col-span-full font-bold">ILTB Tracklocross Series</div>
        <div className="col-span-full"></div>

    </div>

    <div class="relative flex items-center pb-4">
      <div class="flex-grow border-t border-gray-500"></div>
      <span class="flex-shrink mx-4 text-3xl text-monkeypink">Series 1 Champion</span>
      <div class="flex-grow border-t border-gray-500"></div>
    </div>

    <div className="grid grid-cols-1 gap-4 text-center text-2xl pb-4">
        <div className="">! Peter - 44 !</div>
    </div>

  

<div class="relative flex items-center pb-4">
      <div class="flex-grow border-t border-gray-500"></div>
      <span class="flex-shrink mx-4 text-monkeypink">Upcoming Crit Info</span>
      <div class="flex-grow border-t border-gray-500"></div>
    </div>

    


    <div className="grid grid-cols-2 gap-4">
        <div className="text-center">When is the race?</div>
        <div className="text-center">14.10.2023</div>

        </div>


        {/*

        <div className="text-center">When does it start?</div>
        <div className="text-center">We are leaving from S Griebnitzsee at 10:30 - Meet is at 10:00</div>

        <div className="text-center">Where is the race?</div>
        <div className="text-center">In the general area of Güterfelde</div>

        <div className="text-center">Is there an entry fee?</div>
        <div className="text-center">No, the races are completely free!</div>

        <div className="text-center">Will there be a kids race?</div>
        <div className="text-center">Absolutely! Before the main race there is going to be a shorter kids race.</div>
    
        <div className="text-center">How can I get to the race?</div>
        <div className="text-center">There is a groupride starting at S Griebnitzsee at 10:30 - alternativly you can message me or @bikebash144 for the exact location if you want to go there directly</div>
        
        <div className="text-center">Is there a Link for the groupride starting location?</div>
        <div className="text-center "><a className="border-2 align-middle border-monkeylightblue pb-1 pt-1 pl-2 pr-2" href="https://goo.gl/maps/V42fCGqHXfddUZBT8">Google Maps Link </a></div>

        <div className="text-center">How is the course?</div>
        <div className="text-center">Expect the classic ILTB Offroad experience</div>
    
        <div className="text-center">Can I race for a team?</div>
        <div className="text-center">Yes, there is a teams classification in Series 2!</div>

        <div className="text-center">How long is the race?</div>
        <div className="text-center">The race is going to be around 30-40 min of racing time</div>
    
        <div className="text-center">What bike do I need?</div>
        <div className="text-center">Any bike is allowed to participate! You ran ride singlespeed, fixed gear, gravel, cyclocross anything you want. Wider tires are recommended, but in theory it can be done on 25c.</div>

        <div className="text-center">Are there snacks?</div>
        <div className="text-center">The location is a bit more remote this time, it would be better to bring your own food and drinks - potentially some sponsored snacks</div>

        <div className="text-center">Can I just be a spectator?</div>
        <div className="text-center">Yes, absolutlely! It's great to have people watching and cheering on the people racing. Feel free to bring friends and family too! </div>

        <div className="text-center">Can I race as a beginner?</div>
        <div className="text-center">100%! I'm sure you will have alot of fun racing in the dirt!</div>

        <div className="text-center">Will someone have tools?</div>
        <div className="text-center">Yes, there will be a few tools incase something on your bike breaks</div>
    
    
  </div>
  */}
  



<div class="relative flex items-center pb-4 pt-4">
      <div class="flex-grow border-t border-gray-500"></div>
      <span class="flex-shrink mx-4 text-monkeypink">[NAME] Crit Results</span>
      <div class="flex-grow border-t border-gray-500"></div>
    </div>

  <S2NAMECritResults/>




    <div class="relative flex items-center pb-4 pt-4">
      <div class="flex-grow border-t border-gray-500"></div>
      <span class="flex-shrink mx-4 text-monkeypink">Point System </span>
      <div class="flex-grow border-t border-gray-500"></div>
    </div>


    <div className="grid grid-cols-8 gap-2">

        <div className="text-center">- </div>
        <div className="col-span-7">Points are awarded for the top 8 riders after each race</div>
        <div className="text-center">- </div>
        <div className="col-span-7">The sum of all points over the races of a series, result in the series point standings listed below</div>
        <div className="pb-4"></div>
    </div>
    

    <div className="grid grid-cols-8 gap-2 text-center divide-x-2 divide-gray-400">
        <div className="">1</div>
        <div className="">2</div>
        <div className="">3</div>
        <div className="">4</div>
        <div className="">5</div>
        <div className="">6</div>
        <div className="">7</div>
        <div className="">8</div>

    </div>

    <div className="grid grid-cols-8 gap-2 text-center divide-x-2 text-monkeylightblue divide-gray-400">

        <div className="">10</div>
        <div className="">8</div>
        <div className="">6</div>
        <div className="">5</div>
        <div className="">4</div>
        <div className="">3</div>
        <div className="">2</div>
        <div className="">1</div>
    
    </div>

    <div className=" grid grid-cols-3 gap-4">
        
        <div></div>
        <div className="text-center text-md font-semibold pt-8 pb-4">
            Team Classification
        </div>
    </div>

    <div className="grid grid-cols-8 gap-2">

        <div className="text-center">- </div>
        <div className="col-span-7">Optionally, when signing up for the race you can add a team name to your registration</div>
        <div className="text-center">- </div>
        <div className="col-span-7">Points gathered by riders that are part of a team, will be added to the team-score</div>
        <div className="text-center">- </div>
        <div className="col-span-7">Points of all 3 categories (women / fixed / overall) count towards the team classification</div>
        <div className="text-center">- </div>
        <div className="col-span-7">The team with the highest amount of points at the end of the series will win the team classification</div>
    </div>

    
    

    <div class="relative flex items-center pb-4 pt-4">
      <div class="flex-grow border-t border-gray-500"></div>
      <span class="flex-shrink mx-4 text-monkeypink">Series 2 Point Standings</span>
      <div class="flex-grow border-t border-gray-500"></div>
    </div>

    <PointStandings/>

    <div class="relative flex items-center pb-4 pt-4">
      <div class="flex-grow border-t border-gray-500"></div>
      <span class="flex-shrink mx-4 text-monkeypink">Series 2 Women Point Standings</span>
      <div class="flex-grow border-t border-gray-500"></div>
    </div>

    <WomenPointStandings/>

    <div class="relative flex items-center pb-4 pt-4">
      <div class="flex-grow border-t border-gray-500"></div>
      <span class="flex-shrink mx-4 text-monkeypink">Series 2 Fixed Point Standings</span>
      <div class="flex-grow border-t border-gray-500"></div>
    </div>

    <FixedPointStandings/>

    <div class="relative flex items-center pb-4 pt-4">
      <div class="flex-grow border-t border-gray-500"></div>
      <span class="flex-shrink mx-4 text-monkeypink">Series 2 TEAM Point Standings</span>
      <div class="flex-grow border-t border-gray-500"></div>
    </div>

    <TeamPointStandings/>

    <PastRaces/>

    

    </>
  )
}
