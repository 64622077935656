import React from 'react'

export default function PointStandings() {
  return (

    <>
    
    <div class="grid grid-cols-6 gap-3 text-center">

    
      
        <div className="font-semibold">Rank</div>
        <div className="text-center font-semibold"> Name </div>
        <div className="text-center font-semibold"> Number </div>
        <div className="text-center font-semibold"> Points </div>
        <div className="text-center col-span-2 font-semibold">Team</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">1</div>
        <div className="">Xania</div>
        <div className="">666</div>
        <div className="">26</div>
        <div className="col-span-2 text-center">ILTB</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">2</div>
        <div className="">Anja</div>
        <div className="">43</div>
        <div className="">18</div>
        <div className="col-span-2 text-center">Aldi Nord</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">3</div>
        <div className="">Raja</div>
        <div className=""></div>
        <div className="">16</div>
        <div className="col-span-2 text-center">Aldi Nord</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">4</div>
        <div className="">Johanna</div>
        <div className="">31</div>
        <div className="">12</div>
        <div className="col-span-2 text-center">BikeBash144</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">5</div>
        <div className="">Rike</div>
        <div className=""></div>
        <div className="">10</div>
        <div className="col-span-2 text-center"></div>

        <div className="bg-monkeydarkblue text-monkeylightblue">6</div>
        <div className="">Eva</div>
        <div className="">7</div>
        <div className="">8</div>
        <div className="col-span-2 text-center">Ritzelninjas</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">7</div>
        <div className="">Aina</div>
        <div className="">9</div>
        <div className="">5</div>
        <div className="col-span-2 text-center">Ritzelninjas</div>


        



    </div>

    </>

  )
}
