import React from 'react'

export default function SpokesAndCrosses() {
  return (
    <>
    <h1 className="text-center text-3xl text-monkeypink pb-6 pt-3">Spokes and Crosses</h1>

    <div className="grid grid-cols-4 grid-rows-2 gap-3">
      

      <div className=""></div>
      <div className="text-monkeylightblue">Number of Spokes</div>
      <div className="">
        <input type="number" className="text-center w-3/4 h-full bg-monkeydarkblue rounded-lg" id="amountofspokes"/>
      </div>
      <div className=""></div>

      <div className="text-monkeylightblue pl-2">Left Crosses</div>
      <div className="">
        <input type="number" className="text-center w-3/4 h-full bg-monkeydarkblue rounded-lg" id="leftcrosses"/>
      </div>
      <div className="text-monkeylightblue pl-2">Right Crosses</div>
      <div className="">
        <input type="number" className="text-center w-3/4 h-full bg-monkeydarkblue rounded-lg" id="rightcrosses"/>
      </div>
      
    </div>
    </>
  )
}

//<div className="">Number of Right Spokes</div>
  //    <div className="border-2 border-gray-500">
    //    <input type="number" className="w-full h-full" id="rightamountspokes"/>
     // </div>

// <input type="number" className="w-full h-full" id="leftamountspokes"/>