import React from 'react'

import RHCFlyer from './images/finalpostflyerRHC.png';
import CWCFlyer from './images/chasingwinterflyer.png';
import RRCFlyer from './images/realityrumblepost.png';
import NNCPost from './images/neonnightspost.png';
import CCCPost from './images/conqueringcobblespost.png';
import MMCPost from './images/muddymahempost.png';
import NHCPost from './images/newhorizonspost.png';
import SLSPost from './images/sunslitspokespost.png';
import NCPost from './images/namecritpost.png';



export default function PastRaces() {
  return (
    <>
    <div class="relative flex items-center pb-4 pt-4">
      <div class="flex-grow border-t border-gray-500"></div>
      <span class="flex-shrink mx-4 text-monkeypink">Past Races</span>
      <div class="flex-grow border-t border-gray-500"></div>
    </div>

    <div className="grid grid-cols-1 gap-4">
        <div className="col-span-full text-center font-semibold pb-4">Series 1</div>
    </div>

    <div className="grid grid-cols-6 gap-4 flex-col justify-center items-center pb-4">
        <div className="col-span-1 text-center text-monkeylightblue">1 </div>
        <div className="col-span-2 ">Reindeer Hustle Crit</div>
        <div className="col-span-3">
            <img className="pr-2" src={RHCFlyer} alt="Reindeer Hustle Crit"/>
        </div>
    </div>

    <div className="grid grid-cols-6 gap-4 flex-col justify-center items-center pb-4">
        <div className="col-span-1 text-center text-monkeylightblue">2 </div>
        <div className="col-span-2 ">Chasing Winter Crit</div>
        <div className="col-span-3">
            <img className="pr-2" src={CWCFlyer} alt="Chasing Winter Crit"/>
        </div>
    </div>

    <div className="grid grid-cols-6 gap-4 flex-col justify-center items-center pb-4">
        <div className="col-span-1 text-center text-monkeylightblue">3 </div>
        <div className="col-span-2 ">Reality Rumble Crit</div>
        <div className="col-span-3">
            <img className="pr-2" src={RRCFlyer} alt="Reality Rumble Crit"/>
        </div>
    </div>

    <div className="grid grid-cols-6 gap-4 flex-col justify-center items-center pb-4">
        <div className="col-span-1 text-center text-monkeylightblue"> 4 </div>
        <div className="col-span-2 ">
        </div>
        <div className="col-span-3">
            <img className="pr-2" src={NNCPost} alt="Neon Nights Crit"/>
        </div>
    </div>

    <div className="grid grid-cols-6 gap-4 flex-col justify-center items-center pb-4">
        <div className="col-span-1 text-center text-monkeylightblue"> 5 </div>
        <div className="col-span-2 ">Conquering Cobbles Crit</div>
        <div className="col-span-3">
            <img className="pr-2" src={CCCPost} alt="Reality Rumble Crit"/>
        </div>
    </div>

    <div className="grid grid-cols-1 gap-4">
        <div className="col-span-full text-center font-semibold pb-4">Series 2</div>
    </div>

    <div className="grid grid-cols-6 gap-4 flex-col justify-center items-center pb-4">
        <div className="col-span-1 text-center text-monkeylightblue"> 1 </div>
        <div className="col-span-2 ">Muddy Mayhem Crit</div>
        <div className="col-span-3">
            <img className="pr-2" src={MMCPost} alt="Muddy Mayhem Crit"/>
        </div>
    </div>

    <div className="grid grid-cols-6 gap-4 flex-col justify-center items-center pb-4">
        <div className="col-span-1 text-center text-monkeylightblue"> 2 </div>
        <div className="col-span-2 ">New Horizons Crit</div>
        <div className="col-span-3">
            <img className="pr-2" src={NHCPost} alt="New Horizons Crit"/>
        </div>
    </div>

    <div className="grid grid-cols-6 gap-4 flex-col justify-center items-center pb-4">
        <div className="col-span-1 text-center text-monkeylightblue"> 3 </div>
        <div className="col-span-2 ">Sunlit Spokes Crit</div>
        <div className="col-span-3">
            <img className="pr-2" src={SLSPost} alt="SunlitSpokesPost"/>
        </div>
    </div>

    <div className="grid grid-cols-6 gap-4 flex-col justify-center items-center pb-4">
        <div className="col-span-1 text-center text-monkeylightblue"> 4 </div>
        <div className="col-span-2 ">[NAME] Crit</div>
        <div className="col-span-3">
            <img className="pr-2" src={NCPost} alt="[NAME] Crit"/>
        </div>
    </div>

    
    
    </>
  )
}
