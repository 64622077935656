import React from 'react'

export default function PointStandings() {
  return (

    <>
    
    <div class="grid grid-cols-6 gap-3 text-center">

    
      
        <div className="font-semibold">Rank</div>
        <div className="text-center font-semibold"> Name </div>
        <div className="text-center font-semibold"> Number </div>
        <div className="text-center font-semibold"> Points </div>
        <div className="text-center col-span-2 font-semibold">Team</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">1</div>
        <div className="">Pascal</div>
        <div className="">82</div>
        <div className="">20</div>
        <div className="col-span-2 text-center"></div>

        <div className="bg-monkeydarkblue text-monkeylightblue">2</div>
        <div className="">Peter</div>
        <div className="">44</div>
        <div className="">16</div>
        <div className="col-span-2 text-center">fxd.bln</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">3</div>
        <div className="">Paul</div>
        <div className="">1(2)</div>
        <div className="">16</div>
        <div className="col-span-2 text-center"></div>

        <div className="bg-monkeydarkblue text-monkeylightblue">4</div>
        <div className="">Carl</div>
        <div className="">-2</div>
        <div className="">13</div>
        <div className="col-span-2 text-center">Steppenwolf</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">5</div>
        <div className="">Benny</div>
        <div className="">144</div>
        <div className="">11</div>
        <div className="col-span-2 text-center">BikeBash144</div>

        
        
        <div className="bg-monkeydarkblue text-monkeylightblue">6</div>
        <div className="">Steven</div>
        <div className="">4</div>
        <div className="">10</div>
        <div className="col-span-2 text-center">LKK Gravelmania</div>

       

        <div className="bg-monkeydarkblue text-monkeylightblue">7</div>
        <div className="">Pepe</div>
        <div className="">12</div>
        <div className="">9</div>
        <div className="col-span-2 text-center">ILTB</div>

       

        <div className="bg-monkeydarkblue text-monkeylightblue">8</div>
        <div className="">Jost</div>
        <div className="">1.5</div>
        <div className="">9</div>
        <div className="col-span-2 text-center">Basic</div>

        

        <div className="bg-monkeydarkblue text-monkeylightblue">9</div>
        <div className="">Bosse</div>
        <div className="">3</div>
        <div className="">8</div>
        <div className="col-span-2 text-center">LKK Gravelmania</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">10</div>
        <div className="">Pascal</div>
        <div className="">71</div>
        <div className="">8</div>
        <div className="col-span-2 text-center">Happycrew.cc</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">11</div>
        <div className="">Moritz</div>
        <div className=""></div>
        <div className="">8</div>
        <div className="col-span-2 text-center"></div>

        <div className="bg-monkeydarkblue text-monkeylightblue">12</div>
        <div className="">Jonathan</div>
        <div className="">7</div>
        <div className="">6</div>
        <div className="col-span-2 text-center">Steppenwolf</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">13</div>
        <div className="">Micha</div>
        <div className=""></div>
        <div className="">6</div>
        <div className="col-span-2 text-center"></div>

        <div className="bg-monkeydarkblue text-monkeylightblue">14</div>
        <div className="">Flo</div>
        <div className="">999</div>
        <div className="">5</div>
        <div className="col-span-2 text-center"></div>

        <div className="bg-monkeydarkblue text-monkeylightblue">15</div>
        <div className="">Leon</div>
        <div className="">1(1)</div>
        <div className="">4</div>
        <div className="col-span-2 text-center">LKK Gravelmania</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">16</div>
        <div className="">Felix</div>
        <div className="">314</div>
        <div className="">3</div>
        <div className="col-span-2 text-center"></div>

        <div className="bg-monkeydarkblue text-monkeylightblue">17</div>
        <div className="">Arne</div>
        <div className="">11</div>
        <div className="">2</div>
        <div className="col-span-2 text-center">BikeBash144</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">18</div>
        <div className="">Alex</div>
        <div className="">22.2</div>
        <div className="">2</div>
        <div className="col-span-2 text-center"></div>

        <div className="bg-monkeydarkblue text-monkeylightblue">19</div>
        <div className="">Anton</div>
        <div className="">55</div>
        <div className="">1</div>
        <div className="col-span-2 text-center">Steppenwolf</div>

        <div className="bg-monkeydarkblue text-monkeylightblue">20</div>
        <div className="">Artjum</div>
        <div className=""></div>
        <div className="">1</div>
        <div className="col-span-2 text-center"></div>

        



    </div>

    </>

  )
}
