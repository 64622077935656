import { Link } from 'react-router-dom';
import React from 'react'

const Navbar = () => {
  return (
    <nav>
        <div className="grid grid-cols-3 gap-2">
          
          <div className="col-span-full text-lg font-semibold text-center">
            <Link to ='/'>ILikeTrackBikes</Link>
          </div>


          <div className=" text-center">
            <Link to='/'>Spokecalc</Link>
          </div>

          <div className="text-center">
            <Link to='/ILTBTracklocross'>ITLB Tracklocross Series</Link>
          </div>

          <div className="text-center">
            <Link to='/PayPalCalc'>Paypal Fee Calc</Link>
          </div>
          
        </div>

    <div class="relative flex items-center">
      <div class="flex-grow border-t border-monkeylightblue"></div>
      <span class="flex-shrink mx-4 text-monkeypink">+</span>
      <div class="flex-grow border-t border-monkeylightblue"></div>
    </div>
        
    </nav>
  )
}

export default Navbar;