import React from 'react'



export default function PayPalCalc() {
  return (

    <>
    <div className="grid gap-4">
        <div className=""></div>
        <div className="text-center font-bold">PayPal Fee Calculator Coming Soon</div>
        <div className=""></div>
       
    </div>

    
  </>
  )
}
