import React from 'react'

const wrapperFunction2 = () => {
    LeftSideSpokeLength2();
    RightRideSpokeLength2();
}

const RightRideSpokeLength2 = () => {


  //Right side values
  let RightFlangeDiameterField = document.getElementById("rightflangediameter2")
  let CenterToRightFlangeField = document.getElementById("centertorightflange2")
  let RightAmountSpokesField = document.getElementById("amountofspokes2")
  let RightCrossesField = document.getElementById("rightcrosses2")
  

    //General Values
    let ERDField = document.getElementById("ERD2")
    let SpokeHoleDiameterField = document.getElementById("spokeholediameter2")
   

  //Converting the Values to what I need
  let RightFlangeRadiusPowerTwo = Math.pow((RightFlangeDiameterField.value/2),2)
  let RightFlangeRadius = RightFlangeDiameterField.value / 2
  let RightFlangeOffsetPowerTwo = Math.pow(CenterToRightFlangeField.value,2)
  let RightCrosses = RightCrossesField.value
  let RightSpokes = RightAmountSpokesField.value
  

    //General Values
    let RimRadiusPowerTwo = Math.pow((ERDField.value/2),2)
    let RimRadius = ERDField.value/2
    let Spokeholediameter = SpokeHoleDiameterField.value

let RightSpokeLengthResult


RightSpokeLengthResult = Math.sqrt(RimRadiusPowerTwo + RightFlangeRadiusPowerTwo + RightFlangeOffsetPowerTwo - 
  2 * RimRadius * RightFlangeRadius * Math.cos((4*Math.PI*RightCrosses)/RightSpokes)) - (Spokeholediameter / 2)

  let display = document.getElementById("displayright2")
 
  
  
  display.value = ""
  display.value += RightSpokeLengthResult.toFixed(3)
}



const LeftSideSpokeLength2 = () => {


  // Left Side Values
  let LeftFlangeDiameterField = document.getElementById("leftflangediameter2")
  let CenterToLeftFlangeField = document.getElementById("centertoleftflange2")
  let LeftAmountSpokesField = document.getElementById("amountofspokes2")
  let LeftCrossesField = document.getElementById("leftcrosses2")

    //General Values
    let ERDField = document.getElementById("ERD2")
    let SpokeHoleDiameterField = document.getElementById("spokeholediameter2")
   
  
  //Getting values and converting
    
   
  let LeftFlangeRadiusPowerTwo = Math.pow((LeftFlangeDiameterField.value/2),2)
  let LeftFlangeRadius = LeftFlangeDiameterField.value/2
  let LeftFlangeOffsetPowerTwo = Math.pow(CenterToLeftFlangeField.value,2)
  let LeftCrosses = LeftCrossesField.value
  let LeftSpokes = LeftAmountSpokesField.value


    //General Values
    let RimRadiusPowerTwo = Math.pow((ERDField.value/2),2)
    let RimRadius = ERDField.value/2
    let Spokeholediameter = SpokeHoleDiameterField.value
    
  
    let LeftSpokeLengthResult
  
  
    LeftSpokeLengthResult = Math.sqrt(RimRadiusPowerTwo + LeftFlangeRadiusPowerTwo + LeftFlangeOffsetPowerTwo - 
      2 * RimRadius * LeftFlangeRadius * Math.cos((4*Math.PI*LeftCrosses)/LeftSpokes)) - (Spokeholediameter / 2)
   
      //window.alert(LeftSpokeLengthResult.toFixed(4))
  
    //GlobalLeftSpokeLength = LeftSpokeLengthResult
  
    let display = document.getElementById("displayleft2")
  
  
    display.value = ""
    display.value += LeftSpokeLengthResult.toFixed(3) 
    
  
  
  }

export default function Display() {
  return (
    <>

    <div className="grid grid-cols-1 gap-3">   
      
      
      <div className="flex flex-col justify-center items-center pt-10 pb-2">
        <input type="submit" className="font-bold text-2xl border-2 border-monkeylightblue pl-7 pr-7" value="Calculate" onClick = {wrapperFunction2}></input>
      </div>
      
      

      
    </div>
    
    
    <h1 className="text-center text-3xl text-monkeypink pb-2">Result</h1>

    <div className="grid grid-cols-5 gap-3">

      <div className=""></div>

      <div className="col-span-2 text-monkeylightblue">
        Left Spokes
      </div>
      <div className="col-span-2 ">
        <input type="number" className=" rounded-lg text-center bg-monkeydarkblue w-3/4" id="displayleft2" value="" disabled></input>
      </div>
      <div className=""></div>
    

      <div className="col-span-2 text-monkeylightblue ">
        Right Spokes
      </div>
      <div className="col-span-2">
        <input type="number" id="displayright2" className="rounded-lg text-center bg-monkeydarkblue w-3/4" value="" disabled></input>
      </div>
    <div className=""></div>

    </div>
 

    </>
    
  )
}
